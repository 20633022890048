<template>
  <span
    :class="`fi fi-${countryCode.toLowerCase()} fi ${styling} ${props.class ?? ''}`"
  ></span>
</template>

<script setup lang="ts">
const props = defineProps<{
  countryCode: string
  size?: "small" | "normal"
  class?: string
}>()

const styling = computed(() =>
  props?.size === "small" ? "small-flag-icon" : "normal-flag-icon",
)
</script>

<style lang="scss">
.small-flag-icon {
  font-size: 1rem;
  line-height: 1rem;
  height: 1rem;
  width: 1rem;
}

.normal-flag-icon {
  font-size: 1.5rem;
  line-height: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.normal-flag-icon,
.small-flag-icon {
  vertical-align: middle;
}
</style>
